var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-6"},[_c('div',{staticClass:"card card-custom card-stretch gutter-b"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-6"},[_c('b-form-group',{attrs:{"label":"Imię"}},[_c('b-form-input',{model:{value:(_vm.user.firstName),callback:function ($$v) {_vm.$set(_vm.user, "firstName", $$v)},expression:"user.firstName"}})],1)],1),_c('div',{staticClass:"col-xl-6"},[_c('b-form-group',{attrs:{"label":"Nazwisko"}},[_c('b-form-input',{model:{value:(_vm.user.lastName),callback:function ($$v) {_vm.$set(_vm.user, "lastName", $$v)},expression:"user.lastName"}})],1)],1)]),_c('b-form-group',{attrs:{"label":"Adres Email"}},[_c('b-form-input',{attrs:{"type":"email"},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})],1),_c('b-form-group',{attrs:{"label":"Hasło"}},[_c('b-form-input',{attrs:{"type":"password"},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}})],1),_c('b-form-group',{attrs:{"label":"Uprawnienia użytkownika"}},[_c('b-form-checkbox-group',{attrs:{"label":"Uprawnienia","options":[
              {
                text: 'Klienci',
                value: 'clients',
              },
              {
                text: 'Zamówienia',
                value: 'orders',
              },
              {
                text: 'Laboratorium',
                value: 'lab',
              },
              {
                text: 'Uzytkownicy',
                value: 'users',
              },
              {
                text: 'Analiza sprzedaży',
                value: 'analysis',
              },
              {
                text: 'Akceptacja wyników',
                value: 'acceptance',
              }
            ]},model:{value:(_vm.user.roles),callback:function ($$v) {_vm.$set(_vm.user, "roles", $$v)},expression:"user.roles"}})],1),_c('b-button',{staticClass:"mt-3",attrs:{"variant":"primary"},on:{"click":_vm.saveUser}},[_vm._v("Zapisz użytkownika")])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }