<template>
  <div class="row">
    <div class="col-xl-6">
      <div class="card card-custom card-stretch gutter-b">
        <div class="card-body">
          <div class="row">
            <div class="col-xl-6">
              <b-form-group label="Imię">
                <b-form-input v-model="user.firstName" />
              </b-form-group>
            </div>
            <div class="col-xl-6">
              <b-form-group label="Nazwisko">
                <b-form-input v-model="user.lastName" />
              </b-form-group>
            </div>
          </div>

          <b-form-group label="Adres Email">
            <b-form-input type="email" v-model="user.email" />
          </b-form-group>

          <b-form-group label="Hasło">
            <b-form-input type="password" v-model="user.password" />
          </b-form-group>

          <b-form-group label="Uprawnienia użytkownika">
            <b-form-checkbox-group
              label="Uprawnienia"
              v-model="user.roles"
              :options="[
                {
                  text: 'Klienci',
                  value: 'clients',
                },
                {
                  text: 'Zamówienia',
                  value: 'orders',
                },
                {
                  text: 'Laboratorium',
                  value: 'lab',
                },
                {
                  text: 'Uzytkownicy',
                  value: 'users',
                },
                {
                  text: 'Analiza sprzedaży',
                  value: 'analysis',
                },
                {
                  text: 'Akceptacja wyników',
                  value: 'acceptance',
                }
              ]"
            />
          </b-form-group>

          <b-button @click="saveUser" variant="primary" class="mt-3"
          >Zapisz użytkownika</b-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "../../../core/services/store/breadcrumbs.module";
import {USER_LIST} from "../../../core/services/store/user.module";
import {mapGetters} from "vuex";

export default {
  name: "UserAdd",
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title:
          "Moja firma - " +
          (this.$route.params.userId
            ? "Edytuj użytkownika"
            : "Dodaj użytkownika"),
        route: "user",
      },
    ]);

    if ( this.$route.params.userId )
      this.userId = this.$route.params.userId;
  },
  data() {
    return {
      user: {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        roles: [],
      },
      userId: ""
    };
  },
  methods: {
    saveUser() {
      if ( !this.$route.params.userId )
        this.$store.dispatch('userAdd', this.user);
      else
        this.$store.dispatch( 'userEdit', {
          id: this.userId,
          ...this.user
        })

      this.$store.dispatch( USER_LIST );

      this.$router.push('/myCompany/users');
    }
  },
  computed: {
    ...mapGetters(["users"]),
  },
  watch: {
    users: {
      handler() {
        let items = this.users.filter( el => el.id == this.$route.params.userId );

        if ( items.length > 0 ) this.user = items[0];
      },
      immediate: true
    }
  }
};
</script>

<style scoped></style>
